exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-current-contacts-tsx": () => import("./../../../src/pages-current/contacts.tsx" /* webpackChunkName: "component---src-pages-current-contacts-tsx" */),
  "component---src-pages-current-index-tsx": () => import("./../../../src/pages-current/index.tsx" /* webpackChunkName: "component---src-pages-current-index-tsx" */),
  "component---src-pages-current-legal-tsx": () => import("./../../../src/pages-current/legal.tsx" /* webpackChunkName: "component---src-pages-current-legal-tsx" */),
  "component---src-pages-current-payments-rules-tsx": () => import("./../../../src/pages-current/payments-rules.tsx" /* webpackChunkName: "component---src-pages-current-payments-rules-tsx" */),
  "component---src-pages-current-sberbank-payments-rules-tsx": () => import("./../../../src/pages-current/sberbank-payments-rules.tsx" /* webpackChunkName: "component---src-pages-current-sberbank-payments-rules-tsx" */),
  "component---src-pages-current-security-tsx": () => import("./../../../src/pages-current/security.tsx" /* webpackChunkName: "component---src-pages-current-security-tsx" */),
  "component---src-pages-current-user-policy-tsx": () => import("./../../../src/pages-current/user-policy.tsx" /* webpackChunkName: "component---src-pages-current-user-policy-tsx" */)
}

